<template>
  <div>
    <img
      style="width: 100%"
      src="https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/img_solution_bg-qyfl_c.png"
      alt="图片_企业福利"
    />
    <img
      style="width: 100%"
      src="https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/img_solution-procedure_c.png"
      alt="图片_接入流程"
    />

    <section style="background: #fff">
      <div class="solution-title">
        <div>企业福利</div>
        <span>个性化定制企业员工福利，让企业福利更有温度，更有个性，更有价值。</span>
      </div>

      <div class="solution-nav display_flex justify-content_space-around">
        <div @click="changeItem('first')" :class="{ active: curItem == 'first' }">
          入职礼包
        </div>
        <div @click="changeItem('second')" :class="{ active: curItem == 'second' }">
          节日关怀
        </div>
        <div @click="changeItem('third')" :class="{ active: curItem == 'third' }">
          生日礼包
        </div>
        <div @click="changeItem('fourth')" :class="{ active: curItem == 'fourth' }">
          餐饮福利
        </div>
      </div>

      <div class="solution-content display_flex flex-wrap">
        <div
          class="solution-content-item"
          v-for="(item, index) in goodsListObj[curItem]"
          :key="index"
        >
          <span>{{ item.title }}</span>
          <img :src="item.img_url" :alt="item.title" />
        </div>
      </div>
    </section>

    <footer-bar :border_hide="true" />
  </div>
</template>

<script>
import FooterBar from "../../components/FooterBar";

export default {
  data() {
    return {
      curItem: "first",
      goodsListObj: {
        first: [
          {
            title: "帆布包",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/img_rzlb-fbb.png",
          },
          {
            title: "马克杯",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/img_rzlb-mkb.png",
          },
          {
            title: "企业形象公仔",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/img_rzlb-qyxxgz.png",
          },
          {
            title: "个性化入职指南",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/img_rzlb-rzzn.png",
          },
          {
            title: "鼠标垫",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/img_rzlb-sbd.png",
          },
          {
            title: "文化衫",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/img_rzlb-whs.png",
          },
          {
            title: "个性化文具礼盒",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/img_rzlb-wjlh.png",
          },
        ],
        second: [
          {
            title: "中秋节月饼定制",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E8%8A%82%E6%97%A5%E5%85%B3%E6%80%80/%E4%B8%AD%E7%A7%8B%E8%8A%82%E6%9C%88%E9%A5%BC%E5%AE%9A%E5%88%B6.jpeg",
          },
          {
            title: "五一劳动节礼物定制",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E8%8A%82%E6%97%A5%E5%85%B3%E6%80%80/%E4%BA%94%E4%B8%80%E5%8A%B3%E5%8A%A8%E8%8A%82%E7%A4%BC%E7%89%A9%E5%AE%9A%E5%88%B6.jpeg",
          },
          {
            title: "儿童节礼物",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E8%8A%82%E6%97%A5%E5%85%B3%E6%80%80/%E5%84%BF%E7%AB%A5%E8%8A%82%E7%A4%BC%E7%89%A9.jpg",
          },
          {
            title: "国庆节礼物定制",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E8%8A%82%E6%97%A5%E5%85%B3%E6%80%80/%E5%9B%BD%E5%BA%86%E8%8A%82%E7%A4%BC%E7%89%A9%E5%AE%9A%E5%88%B6.jpeg",
          },
          {
            title: "妇女节礼物",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E8%8A%82%E6%97%A5%E5%85%B3%E6%80%80/%E5%A6%87%E5%A5%B3%E8%8A%82%E7%A4%BC%E7%89%A9.jpg",
          },
          {
            title: "情人节礼物定制",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E8%8A%82%E6%97%A5%E5%85%B3%E6%80%80/%E6%83%85%E4%BA%BA%E8%8A%82%E7%A4%BC%E7%89%A9%E5%AE%9A%E5%88%B6-2.png",
          },
          {
            title: "新年礼包定制",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E8%8A%82%E6%97%A5%E5%85%B3%E6%80%80/%E6%96%B0%E5%B9%B4%E7%A4%BC%E5%8C%85%E5%AE%9A%E5%88%B6.jpeg",
          },
          {
            title: "红包定制",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E8%8A%82%E6%97%A5%E5%85%B3%E6%80%80/%E7%BA%A2%E5%8C%85%E5%AE%9A%E5%88%B6.jpeg",
          },
        ],
        third: [
          {
            title: "生日相册",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E7%94%9F%E6%97%A5%E7%A6%8F%E5%88%A9/%E7%94%9F%E6%97%A5%E7%9B%B8%E5%86%8C.jpg",
          },
          {
            title: "生日祝福鲜花",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E7%94%9F%E6%97%A5%E7%A6%8F%E5%88%A9/%E7%94%9F%E6%97%A5%E7%A5%9D%E7%A6%8F%E9%B2%9C%E8%8A%B1.jpg",
          },
          {
            title: "生日蛋糕定制",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E7%94%9F%E6%97%A5%E7%A6%8F%E5%88%A9/%E7%94%9F%E6%97%A5%E8%9B%8B%E7%B3%95%E5%AE%9A%E5%88%B6.jpeg",
          },
          {
            title: "生日贺卡定制",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E7%94%9F%E6%97%A5%E7%A6%8F%E5%88%A9/%E7%94%9F%E6%97%A5%E8%B4%BA%E5%8D%A1%E5%AE%9A%E5%88%B6-2.png",
          },
        ],
        fourth: [
          {
            title: "坚果礼包",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E9%A4%90%E9%A5%AE%E7%A6%8F%E5%88%A9/%E5%9D%9A%E6%9E%9C%E7%A4%BC%E5%8C%85.jpeg",
          },
          {
            title: "定制一次性水杯",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E9%A4%90%E9%A5%AE%E7%A6%8F%E5%88%A9/%E5%AE%9A%E5%88%B6%E4%B8%80%E6%AC%A1%E6%80%A7%E6%B0%B4%E6%9D%AF.jpeg",
          },
          {
            title: "定制矿泉水",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E9%A4%90%E9%A5%AE%E7%A6%8F%E5%88%A9/%E5%AE%9A%E5%88%B6%E7%9F%BF%E6%B3%89%E6%B0%B4.jpeg",
          },
          {
            title: "定制面包卡",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E9%A4%90%E9%A5%AE%E7%A6%8F%E5%88%A9/%E5%AE%9A%E5%88%B6%E9%9D%A2%E5%8C%85%E5%8D%A1.jpg",
          },
          {
            title: "定制饮料",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E9%A4%90%E9%A5%AE%E7%A6%8F%E5%88%A9/%E5%AE%9A%E5%88%B6%E9%A5%AE%E6%96%99.jpg",
          },
          {
            title: "零食礼包定制",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E9%A4%90%E9%A5%AE%E7%A6%8F%E5%88%A9/%E9%9B%B6%E9%A3%9F%E7%A4%BC%E5%8C%85%E5%AE%9A%E5%88%B6.jpeg",
          },
          {
            title: "饼干定制",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E9%A4%90%E9%A5%AE%E7%A6%8F%E5%88%A9/%E9%A5%BC%E5%B9%B2%E5%AE%9A%E5%88%B6.jpeg",
          },
        ],
      },
    };
  },
  components: {
    FooterBar,
  },
  methods: {
    changeItem(curItem) {
      this.curItem = curItem;
    },
  },
};
</script>

<style lang="scss">
@import "./style/index.scss";
</style>
