<template>
  <div class="container">
    <!-- banner -->
  
  <swiper ref="mySwiper" :options="swiperOptions">
    <swiper-slide v-for="page in 23" :key="page">
      <div style="height: 100px;background-color: #fff;"></div>
      <img
              style="width: 100%"
              :src="'https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/vsp/page_'+page+'.png'"
              alt="banner_电商化采购"
            />
    </swiper-slide>
  
    <div class="swiper-pagination" slot="pagination"></div>
    
  </swiper>
  

    <div id="business-buy-desc">“ 我们专注大中型企业电商化采购综合解决方案 ”</div>

    <!-- 采购数字化 -->
    <section class="business-buy">
      <div>采购数字化，重塑采购流程，推动数字转型</div>
      <span>通过SaaS应用，建立企业采购标准化，数字化，为企业带来多重价值</span>

      <div class="business-buy-container display_flex justify-content_space-between">
        <div
          class="business-buy-item display_flex flex-direction_column justify-content_flex-center align-items_center"
        >
          <img
            src="https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/icon_buy-dshcg.png"
            alt="图片-电商化采购"
          />
          <div>电商化采购</div>
          <p>为企业直连优质电商平台，物资品类全、价格低、供应链能力强。</p>
        </div>

        <div
          class="business-buy-item display_flex flex-direction_column justify-content_flex-center align-items_center"
        >
          <img
            src="https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/icon_buy-szhcg.png"
            alt="图片-数字化采购"
          />
          <div>数字化采购</div>
          <p>
            为企业打造包含业务、技术、数据三个层面的采购中台，帮助企业快速构建采购数字化。
          </p>
        </div>

        <div
          class="business-buy-item display_flex flex-direction_column justify-content_flex-center align-items_center"
        >
          <img
            src="https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/icon_buy-cgdn.png"
            alt="图片-采购大脑"
          />
          <div>采购大脑</div>
          <p>为企业采购装上智能大脑，采购需求预测、支出分析和预测，支撑企业决策。</p>
        </div>

        <div
          class="business-buy-item display_flex flex-direction_column justify-content_flex-center align-items_center"
        >
          <img
            src="https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/icon_buy-cgxt.png"
            alt="图片-采购协同"
          />
          <div>采购协同</div>
          <p>连接采购端与供应商端，实现从订单到支付的全流程在线协同，提高采购效率。</p>
        </div>

        <div
          class="business-buy-item display_flex flex-direction_column justify-content_flex-center align-items_center"
        >
          <img
            src="https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/icon_buy-zhyy.png"
            alt="图片-智慧运营"
          />
          <div>智慧运营</div>
          <p>提高企业员工满意度，提高供应商在线协同率，节省成本，提高效率。</p>
        </div>
      </div>
    </section>

    <!-- 实施服务保障 -->
    <section class="business-buy" style="padding: 0; background: #fff; height: 560px">
      <div>实施服务保障</div>
      <span>搭建售前、实施、售后各个任务模块团队，进行项目过程全服务</span>

      <div style="height: 300px">
        <img
          style="width: 100%"
          src="https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/img_buy-ensure_c.png"
          alt="图片-实施服务保障"
        />
      </div>
    </section>

    <!-- 底部栏 -->
    <footer-bar :border_hide="true" />
  </div>
</template>

<script>
import FooterBar from "../components/FooterBar";

export default {
  name: "BusinessBuy",
  data() {
    return {
      isActive: true,
      swiperOptions: {
         mousewheel: true,
          // loop: true,
          speed: 1000,
          autoplay: {
            delay: 2000,
            stopOnLastSlide: false,
            disableOnInteraction: false,
          },
          pagination: {
            el: '.swiper-pagination'
          },
          // Some Swiper option/callback...
        }
    };
  },
  components: {
    FooterBar,
  },
  methods: {
    changeItem(curItem) {
      this.curItem = curItem;
    },
  },
};
</script>

<style scoped lang="scss">
#business-buy-desc {
  height: 120px;
  background: linear-gradient(270deg, #38c2ff 0%, #1a8fff 100%);
  text-align: center;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 120px;
}

.business-buy {
  text-align: center;
  padding-bottom: 28px;
  height: 506px;
  padding: 0 100px;
  background-image: url("https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/img_bg-buy.png");

  > div:nth-child(1) {
    font-size: 30px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #16181d;
    line-height: 45px;
    padding: 60px 0 8px 0;
  }

  > span {
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #6a7181;
    line-height: 30px;
    display: inline-block;
    margin-bottom: 44px;
  }
}

.business-buy-container {
  .business-buy-item {
    width: 18%;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #9ca7c2;
    padding: 28px 0 36px 0;

    img {
      width: 80px;
      height: 81px;
    }

    div {
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #16181d;
      line-height: 28px;
      margin-top: 18px;
      margin-bottom: 20px;
    }

    p {
      padding: 0 12px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #9ca7c2;
      line-height: 20px;

      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }

  .business-buy-item:hover {
    cursor: pointer;
    box-shadow: 0px 20px 40px 0px rgba(29, 51, 107, 0.1);
    border: 1px solid #1a8fff;
  }

  .business-buy-item.active {
    background: #1a8fff;
    color: #fff;
    box-shadow: 0px 20px 40px 0px rgba(29, 51, 107, 0.1);
  }
}

@media screen and (min-width: 1600px) {
  .business-buy {
    padding: 0;
  }
}
</style>
