<template>
  <div class="container">
    <!-- banner -->
    <section class="banner">
      <img
        style="width: 100%"
        src="https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/img_banner-about-us.jpeg"
        alt="banner_关于我们"
      />
    </section>

    <section id="about-us-container">
      <!-- 荣誉与证书 -->
      <div class="about-us-certify">
        <div style="width: 100%; position: relative">
           <div class="desc">
            <div>荣誉与证书</div>
            <div class="line"></div>
          </div>
          <swiper :options="swipersuccess">
            <swiper-slide>
              <img src="https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/about/certify01.png" alt="">
              <p>增值电信业务经营许可证</p>
            </swiper-slide>
            <swiper-slide>
              <img src="https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/about/certify02.png" alt="">
              <p>15项计算机软件著作权登记证书</p>
            </swiper-slide>
            <swiper-slide>
              <img src="https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/about/certify03.png" alt="">
              <p>上海市科技型中小企业</p>
            </swiper-slide>

            <swiper-slide>
              <img src="https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/about/certify04.png" alt="">
              <p>上海市创新型中小企业</p>
            </swiper-slide>

            <swiper-slide>
              <img src="https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/about/certify05.png" alt="">
              <p>5项实用新型专利</p>
            </swiper-slide>

            <swiper-slide>
              <img src="https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/about/certify06.png" alt="">
              <p>8项授权商标</p>
            </swiper-slide>

             <swiper-slide>
              <img src="https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/about/certify07.png" alt="">
              <p>50+知名品牌授权</p>
            </swiper-slide>
            
          </swiper>
      </div>
      </div>
      <!-- 我们的使命 -->
      <div class="about-us-mission">
        <div style="width: 100%; position: relative">
          <img
            class="about-us-img"
            src="https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/img-mission_c.png"
            alt="图片-我们的使命"
          />
          <div class="about-us-desc">
            <div>我们的使命</div>
            <div class="line"></div>
            <p>“打造企业级柔性供应链定制平台，实现个性定制化服务。”</p>
          </div>
        </div>
      </div>

      <!-- 关于我们 -->
      <div class="about-us-mission about-us-style">
        <div style="width: 100%; position: relative">
          <div class="about-us-desc">
            <div>关于我们</div>
            <div class="line"></div>
            <p style="width: 86%">
              闪定科技核心团队来自于京东，阿里互联网公司，都在企业服务电商化供应链采购领域深耕10年以上。
              我们深信未来线上化、智能化、数据化的柔性供应链，会在企业的需求中扮演重要的角色。
              给企业在零散物料定制采购，带去便捷的同时,合规、成本降低、满意度提升。
              闪定科技致力于在企业福利、营销物料、商务馈赠、宣传品、IP衍生品等场景里提供线上智能的供应链解决方案。
              于2020年正式起航。
            </p>
          </div>
          <img
            class="about-us-img"
            src="https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/img-us_c.png"
            alt="图片-关于我们"
          />
        </div>
      </div>
      
    </section>

    <!-- 底部栏 -->
    <footer-bar />
  </div>
</template>

<script>
import FooterBar from "../components/FooterBar";

export default {
  name: "AboutUs",
  data() {
    return {
      swipersuccess:{
          effect:"coverflow",
          grabCursor: true,
          watchSlidesProgress: true,
          centeredSlides: true,
          loop: true,
          loopedSlides: 3,
          slidesPerView: 3,
          autoplay: {
            delay: 3000,//自动播放速度
            disableOnInteraction: false//鼠标移上去时是否还继续播放
          },
          coverflowEffect: {
            // rotate: 50,
            // stretch: 0,
            // depth: 500,
            // modifier: 1,
            slideShadows : true,
            rotate: 30,
            stretch: 0,
            depth: 60,
            modifier: 6,
            slideShadows : false
          },
      },
    };
  },
  components: {
    FooterBar,
  },
};
</script>

<style scoped lang="scss">
.about-us-mission {
  margin-top: 60px;
  margin-bottom: 80px;

  .about-us-img {
    width: 34%;
    // height: 350px;
    position: absolute;
    top: 20px;
  }

  .about-us-desc {
    margin-left: auto;
    width: 74%;
    height: 350px;
    background: #ffffff;
    padding: 60px 0 0 11%;

    div {
      font-size: 30px;
      font-weight: 700;
      color: #2c3e50;
      line-height: 44px;
    }

    .line {
      width: 50px;
      height: 2px;
      background: #1a8fff;
      margin-top: 10px;
      margin-bottom: 40px;
    }

    p {
      width: 732px;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #6a7181;
      line-height: 1.5;
    }
  }
}

.about-us-style {
  .about-us-img {
    top: 20px;
    right: 0;
  }
  .about-us-desc {
    margin: 0;
    padding: 60px 0 0 34px;
  }
}

#about-us-container {
  padding: 0 100px;
}

@media screen and (min-width: 1600px) {
  #about-us-container {
    padding: 0;
  }
}
.about-us-certify {
  margin-top: 60px;
  background-color: #fff;
}
.about-us-certify .swiper-slide {
    width: 480px;
    height: 408px;
    background: #fff;
    box-shadow: 0 8px 30px #ddd;
}
.about-us-certify .swiper-slide img {
    display: block;
    width: 100%;
}

.about-us-certify .swiper-slide p {
    width: 480px;
    line-height: 98px;
    padding-top: 0;
    text-align: center;
    color: #636363;
    font-size: 1.1em;
    margin: 0;
}

.about-us-certify .desc {
  margin: 0;
  padding: 20px 0 0 34px;
  font-size: 30px;
  font-weight: 700;
  color: #2c3e50;
  line-height: 44px;
}
.about-us-certify .line {
   width: 50px;
      height: 2px;
      background: #1a8fff;
      margin-top: 10px;
      margin-bottom: 40px;
}
</style>
