<template>
  <div>
    <img
      style="width: 100%"
      src="https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/img_solution_bg-lssd_c.png"
      alt="图片_连锁商店"
    />
    <img
      style="width: 100%"
      src="https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/img_solution-procedure_c.png"
      alt="图片_接入流程"
    />

    <section style="background: #fff">
      <div class="solution-title">
        <div>连锁商店</div>
        <span>品牌标识物，日常耗材自由定制，零库存。</span>
      </div>

      <div class="solution-nav display_flex justify-content_space-around">
        <div @click="changeItem('first')" :class="{ active: curItem == 'first' }">
          酒店物料
        </div>
        <div @click="changeItem('second')" :class="{ active: curItem == 'second' }">
          餐饮物料
        </div>
        <div @click="changeItem('third')" :class="{ active: curItem == 'third' }">
          地推物料
        </div>
        <!-- <div @click="changeItem('fourth')" :class="{ active: curItem == 'fourth' }">
          快消品物料
        </div> -->
      </div>

      <div class="solution-content display_flex flex-wrap">
        <div
          class="solution-content-item"
          v-for="(item, index) in goodsListObj[curItem]"
          :key="index"
        >
          <span>{{ item.title }}</span>
          <img :src="item.img_url" :alt="item.title" />
        </div>
      </div>
    </section>

    <footer-bar :border_hide="true" />
  </div>
</template>

<script>
import FooterBar from "../../components/FooterBar";

export default {
  data() {
    return {
      curItem: "first",
      goodsListObj: {
        first: [
          {
            title: "大号帆布袋",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E8%BF%9E%E9%94%81%E5%95%86%E5%BA%97/%E5%A4%A7%E5%8F%B7%E5%B8%86%E5%B8%83%E8%A2%8B.png",
          },
          {
            title: "定制信封",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E8%BF%9E%E9%94%81%E5%95%86%E5%BA%97/%E5%AE%9A%E5%88%B6%E4%BF%A1%E5%B0%81.png",
          },
          {
            title: "小心地滑",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E8%BF%9E%E9%94%81%E5%95%86%E5%BA%97/%E5%B0%8F%E5%BF%83%E5%9C%B0%E6%BB%91.png",
          },
          {
            title: "房卡夹",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E8%BF%9E%E9%94%81%E5%95%86%E5%BA%97/%E6%88%BF%E5%8D%A1%E5%A4%B9.png",
          },
          {
            title: "推拉门标识",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E8%BF%9E%E9%94%81%E5%95%86%E5%BA%97/%E6%8E%A8%E6%8B%89%E9%97%A8%E6%A0%87%E8%AF%86.png",
          },
          {
            title: "纸杯",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E8%BF%9E%E9%94%81%E5%95%86%E5%BA%97/%E7%BA%B8%E6%9D%AF.png",
          },
          {
            title: "行李牌",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E8%BF%9E%E9%94%81%E5%95%86%E5%BA%97/%E8%A1%8C%E6%9D%8E%E7%89%8C.png",
          },
          {
            title: "酒精棉片",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E8%BF%9E%E9%94%81%E5%95%86%E5%BA%97/%E9%85%92%E7%B2%BE%E6%A3%89%E7%89%87.png",
          },
        ],
        second: [
          {
            title: "定制代金券",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E9%A4%90%E9%A5%AE%E7%89%A9%E6%96%99/%E5%AE%9A%E5%88%B6%E4%BB%A3%E9%87%91%E5%88%B8-2.png",
          },
          {
            title: "定制取餐叫号牌",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E9%A4%90%E9%A5%AE%E7%89%A9%E6%96%99/%E5%AE%9A%E5%88%B6%E5%8F%96%E9%A4%90%E5%8F%AB%E5%8F%B7%E7%89%8C.jpg",
          },
          {
            title: "定制打包盒",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E9%A4%90%E9%A5%AE%E7%89%A9%E6%96%99/%E5%AE%9A%E5%88%B6%E6%89%93%E5%8C%85%E7%9B%92.jpg",
          },
          {
            title: "定制打包纸袋",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E9%A4%90%E9%A5%AE%E7%89%A9%E6%96%99/%E5%AE%9A%E5%88%B6%E6%89%93%E5%8C%85%E7%BA%B8%E8%A2%8B.jpg",
          },
          {
            title: "定制纸巾",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E9%A4%90%E9%A5%AE%E7%89%A9%E6%96%99/%E5%AE%9A%E5%88%B6%E7%BA%B8%E5%B7%BE.jpeg",
          },
          {
            title: "定制菜谱",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E9%A4%90%E9%A5%AE%E7%89%A9%E6%96%99/%E5%AE%9A%E5%88%B6%E8%8F%9C%E8%B0%B1.jpg",
          },
          {
            title: "宣传单页",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E9%A4%90%E9%A5%AE%E7%89%A9%E6%96%99/%E5%AE%A3%E4%BC%A0%E5%8D%95%E9%A1%B5.jpg",
          },
        ],
        third: [
          {
            title: "地推四件套",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E5%9C%B0%E6%8E%A8%E7%89%A9%E6%96%99/%E5%9C%B0%E6%8E%A8%E5%9B%9B%E4%BB%B6%E5%A5%97.jpeg",
          },
          {
            title: "地推展架",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E5%9C%B0%E6%8E%A8%E7%89%A9%E6%96%99/%E5%9C%B0%E6%8E%A8%E5%B1%95%E6%9E%B6.jpg",
          },
          {
            title: "定制塑料扇",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E5%9C%B0%E6%8E%A8%E7%89%A9%E6%96%99/%E5%AE%9A%E5%88%B6%E5%A1%91%E6%96%99%E6%89%87.jpg",
          },
          {
            title: "宣传单",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E5%9C%B0%E6%8E%A8%E7%89%A9%E6%96%99/%E5%AE%A3%E4%BC%A0%E5%8D%95.jpg",
          },
          {
            title: "抽奖大转盘",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E5%9C%B0%E6%8E%A8%E7%89%A9%E6%96%99/%E6%8A%BD%E5%A5%96%E5%A4%A7%E8%BD%AC%E7%9B%98.jpg",
          },
          {
            title: "易拉宝",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E5%9C%B0%E6%8E%A8%E7%89%A9%E6%96%99/%E6%98%93%E6%8B%89%E5%AE%9D.jpg",
          },
        ],
        fourth: [
          {
            title: "帆布包",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/img_rzlb-fbb.png",
          },
          {
            title: "马克杯",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/img_rzlb-mkb.png",
          },
        ],
      },
    };
  },
  components: {
    FooterBar,
  },
  methods: {
    changeItem(curItem) {
      this.curItem = curItem;
    },
  },
};
</script>

<style lang="scss">
@import "./style/index.scss";
</style>
