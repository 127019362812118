<template>
  <div>
    <img
      style="width: 100%"
      src="https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/img_solution_bg-llbx_c.png"
      alt="图片_流量变现"
    />
    <img
      style="width: 100%"
      src="https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/img_solution-procedure_c.png"
      alt="图片_接入流程"
    />

    <section style="background: #fff">
      <div class="solution-title">
        <div>流量变现</div>
        <span>私域流量，企业电商，更好的个性化服务。</span>
      </div>

      <div class="solution-nav display_flex justify-content_space-around">
        <div @click="changeItem('first')" :class="{ active: curItem == 'first' }">
          电商变现
        </div>
        <div @click="changeItem('second')" :class="{ active: curItem == 'second' }">
          积分变现
        </div>
        <!-- <div @click="changeItem('third')" :class="{ active: curItem == 'third' }">
          私域流量
        </div>
        <div @click="changeItem('fourth')" :class="{ active: curItem == 'fourth' }">
          集团电商
        </div> -->
      </div>

      <div class="solution-content display_flex flex-wrap">
        <div
          class="solution-content-item"
          v-for="(item, index) in goodsListObj[curItem]"
          :key="index"
        >
          <span>{{ item.title }}</span>
          <img :src="item.img_url" :alt="item.title" />
        </div>
      </div>
    </section>

    <footer-bar :border_hide="true" />
  </div>
</template>

<script>
import FooterBar from "../../components/FooterBar";

export default {
  data() {
    return {
      curItem: "first",
      goodsListObj: {
        first: [
          {
            title: "4寸Lomo卡照片",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E6%B5%81%E9%87%8F%E5%8F%98%E7%8E%B0/4%E5%AF%B8Lomo%E5%8D%A1%E7%85%A7%E7%89%87.jpg",
          },
          {
            title: "DIY三合一伸缩数据线",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E6%B5%81%E9%87%8F%E5%8F%98%E7%8E%B0/DIY%E4%B8%89%E5%90%88%E4%B8%80%E4%BC%B8%E7%BC%A9%E6%95%B0%E6%8D%AE%E7%BA%BF.jpg",
          },
          {
            title: "DIY临时停车牌",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E6%B5%81%E9%87%8F%E5%8F%98%E7%8E%B0/DIY%E4%B8%B4%E6%97%B6%E5%81%9C%E8%BD%A6%E7%89%8C.jpg",
          },
          {
            title: "DIY伸缩折叠手机支架",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E6%B5%81%E9%87%8F%E5%8F%98%E7%8E%B0/DIY%E4%BC%B8%E7%BC%A9%E6%8A%98%E5%8F%A0%E6%89%8B%E6%9C%BA%E6%94%AF%E6%9E%B6.jpg",
          },
          {
            title: "DIY儿童卡通雨伞",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E6%B5%81%E9%87%8F%E5%8F%98%E7%8E%B0/DIY%E5%84%BF%E7%AB%A5%E5%8D%A1%E9%80%9A%E9%9B%A8%E4%BC%9E.png",
          },
          {
            title: "DIY儿童定制书包",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E6%B5%81%E9%87%8F%E5%8F%98%E7%8E%B0/DIY%E5%84%BF%E7%AB%A5%E5%AE%9A%E5%88%B6%E4%B9%A6%E5%8C%85.jpg",
          },
          {
            title: "DIY创意书立2件套.",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E6%B5%81%E9%87%8F%E5%8F%98%E7%8E%B0/DIY%E5%88%9B%E6%84%8F%E4%B9%A6%E7%AB%8B2%E4%BB%B6%E5%A5%97.jpg",
          },
        ],
        second: [
          {
            title: "DIY多功能抱枕被",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E6%B5%81%E9%87%8F%E5%8F%98%E7%8E%B0/DIY%E5%A4%9A%E5%8A%9F%E8%83%BD%E6%8A%B1%E6%9E%95%E8%A2%AB.jpg",
          },
          {
            title: "DIY定制桌垫",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E6%B5%81%E9%87%8F%E5%8F%98%E7%8E%B0/DIY%E5%AE%9A%E5%88%B6%E6%A1%8C%E5%9E%AB.jpg",
          },
          {
            title: "DIY定制胶卷相册",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E6%B5%81%E9%87%8F%E5%8F%98%E7%8E%B0/DIY%E5%AE%9A%E5%88%B6%E8%83%B6%E5%8D%B7%E7%9B%B8%E5%86%8C.jpg",
          },
          {
            title: "DIY定制行李箱",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E6%B5%81%E9%87%8F%E5%8F%98%E7%8E%B0/DIY%E5%AE%9A%E5%88%B6%E8%A1%8C%E6%9D%8E%E7%AE%B1.png",
          },
          {
            title: "DIY定制颈枕",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E6%B5%81%E9%87%8F%E5%8F%98%E7%8E%B0/DIY%E5%AE%9A%E5%88%B6%E9%A2%88%E6%9E%95.jpg",
          },
          {
            title: "DIY立体相框摆台",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E6%B5%81%E9%87%8F%E5%8F%98%E7%8E%B0/DIY%E7%AB%8B%E4%BD%93%E7%9B%B8%E6%A1%86%E6%91%86%E5%8F%B0.jpg",
          },
          {
            title: "DIY趣味iPad保护套",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E6%B5%81%E9%87%8F%E5%8F%98%E7%8E%B0/DIY%E8%B6%A3%E5%91%B3iPad%E4%BF%9D%E6%8A%A4%E5%A5%97.jpg",
          },
        ],
        third: [
          {
            title: "DIY车载镶钻摇摇乐",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E6%B5%81%E9%87%8F%E5%8F%98%E7%8E%B0/DIY%E8%BD%A6%E8%BD%BD%E9%95%B6%E9%92%BB%E6%91%87%E6%91%87%E4%B9%90.jpg",
          },
          {
            title: "TPUAirPods保护壳",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E6%B5%81%E9%87%8F%E5%8F%98%E7%8E%B0/TPUAirPods%E4%BF%9D%E6%8A%A4%E5%A3%B3.jpg",
          },
          {
            title: "个性定制中性笔",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E6%B5%81%E9%87%8F%E5%8F%98%E7%8E%B0/%E4%B8%AA%E6%80%A7%E5%AE%9A%E5%88%B6%E4%B8%AD%E6%80%A7%E7%AC%94.jpg",
          },
          {
            title: "个性定制凉拖鞋",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E6%B5%81%E9%87%8F%E5%8F%98%E7%8E%B0/%E4%B8%AA%E6%80%A7%E5%AE%9A%E5%88%B6%E5%87%89%E6%8B%96%E9%9E%8B.png",
          },
          {
            title: "加绒圆领卫衣",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E6%B5%81%E9%87%8F%E5%8F%98%E7%8E%B0/%E5%8A%A0%E7%BB%92%E5%9C%86%E9%A2%86%E5%8D%AB%E8%A1%A3.jpg",
          },
          {
            title: "可移胶儿童身高贴纸",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E6%B5%81%E9%87%8F%E5%8F%98%E7%8E%B0/%E5%8F%AF%E7%A7%BB%E8%83%B6%E5%84%BF%E7%AB%A5%E8%BA%AB%E9%AB%98%E8%B4%B4%E7%BA%B8.jpg",
          },
        ],
        fourth: [
          {
            title: "定制围裙",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E6%B5%81%E9%87%8F%E5%8F%98%E7%8E%B0/%E5%AE%9A%E5%88%B6%E5%9B%B4%E8%A3%99.jpg",
          },
          {
            title: "定制收纳洗漱包",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E6%B5%81%E9%87%8F%E5%8F%98%E7%8E%B0/%E5%AE%9A%E5%88%B6%E6%94%B6%E7%BA%B3%E6%B4%97%E6%BC%B1%E5%8C%85%20.jpg",
          },
          {
            title: "定制无线充电器",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E6%B5%81%E9%87%8F%E5%8F%98%E7%8E%B0/%E5%AE%9A%E5%88%B6%E6%97%A0%E7%BA%BF%E5%85%85%E7%94%B5%E5%99%A8.jpg",
          },
          {
            title: "磁性照片冰箱贴",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E6%B5%81%E9%87%8F%E5%8F%98%E7%8E%B0/%E7%A3%81%E6%80%A7%E7%85%A7%E7%89%87%E5%86%B0%E7%AE%B1%E8%B4%B4.jpg",
          },
          {
            title: "简约文艺帆布包",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E6%B5%81%E9%87%8F%E5%8F%98%E7%8E%B0/%E7%AE%80%E7%BA%A6%E6%96%87%E8%89%BA%E5%B8%86%E5%B8%83%E5%8C%85.jpg",
          },
        ],
      },
    };
  },
  components: {
    FooterBar,
  },
  methods: {
    changeItem(curItem) {
      this.curItem = curItem;
    },
  },
};
</script>

<style lang="scss">
@import "./style/index.scss";
</style>
