<template>
  <div>
    <img
      style="width: 100%"
      src="https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/img_solution_bg-bgwy_c.png"
      alt="图片_办公文印"
    />
    <img
      style="width: 100%"
      src="https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/img_solution-procedure_c.png"
      alt="图片_接入流程"
    />

    <section style="background: #fff">
      <div class="solution-title">
        <div>办公文印</div>
        <span>名片、品牌展示、办公耗品，根据企业文化个性定制。</span>
      </div>

      <div class="solution-nav display_flex justify-content_space-around">
        <div @click="changeItem('first')" :class="{ active: curItem == 'first' }">
          企业文化
        </div>
        <div @click="changeItem('second')" :class="{ active: curItem == 'second' }">
          日常办公
        </div>
        <!-- <div @click="changeItem('third')" :class="{ active: curItem == 'third' }">
          私域流量
        </div>
        <div @click="changeItem('fourth')" :class="{ active: curItem == 'fourth' }">
          集团电商
        </div> -->
      </div>

      <div class="solution-content display_flex flex-wrap">
        <div
          class="solution-content-item"
          v-for="(item, index) in goodsListObj[curItem]"
          :key="index"
        >
          <span>{{ item.title }}</span>
          <img :src="item.img_url" :alt="item.title" />
        </div>
      </div>
    </section>

    <footer-bar :border_hide="true" />
  </div>
</template>

<script>
import FooterBar from "../../components/FooterBar";

export default {
  data() {
    return {
      curItem: "first",
      goodsListObj: {
        first: [
          {
            title: "企业logo衍生品",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E5%8A%9E%E5%85%AC%E6%96%87%E5%8D%B0/%E4%BC%81%E4%B8%9Alogo%E8%A1%8D%E7%94%9F%E5%93%81.jpg",
          },
          {
            title: "企业吉祥物定制",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E5%8A%9E%E5%85%AC%E6%96%87%E5%8D%B0/%E4%BC%81%E4%B8%9A%E5%90%89%E7%A5%A5%E7%89%A9%E5%AE%9A%E5%88%B6-2.png",
          },
          {
            title: "企业定制相册",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E5%8A%9E%E5%85%AC%E6%96%87%E5%8D%B0/%E4%BC%81%E4%B8%9A%E5%AE%9A%E5%88%B6%E7%9B%B8%E5%86%8C-2.png",
          },
          {
            title: "企业宣传标语定制",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E5%8A%9E%E5%85%AC%E6%96%87%E5%8D%B0/%E4%BC%81%E4%B8%9A%E5%AE%A3%E4%BC%A0%E6%A0%87%E8%AF%AD%E5%AE%9A%E5%88%B6.jpeg",
          },
          {
            title: "企业文化衫定制",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E5%8A%9E%E5%85%AC%E6%96%87%E5%8D%B0/%E4%BC%81%E4%B8%9A%E6%96%87%E5%8C%96%E8%A1%AB%E5%AE%9A%E5%88%B6.jpeg",
          },
          {
            title: "企业胸牌",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E5%8A%9E%E5%85%AC%E6%96%87%E5%8D%B0/%E4%BC%81%E4%B8%9A%E8%83%B8%E7%89%8C.jpeg",
          },
          {
            title: "定制奖杯",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E5%8A%9E%E5%85%AC%E6%96%87%E5%8D%B0/%E5%AE%9A%E5%88%B6%E5%A5%96%E6%9D%AF.jpg",
          },
          {
            title: "文化墙",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E5%8A%9E%E5%85%AC%E6%96%87%E5%8D%B0/%E6%96%87%E5%8C%96%E5%A2%99-2.png",
          },
        ],
        second: [
          {
            title: "便签纸",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E5%8A%9E%E5%85%AC%E6%96%87%E5%8D%B0/%E4%BE%BF%E7%AD%BE%E7%BA%B8.jpg",
          },
          {
            title: "抽纸",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E5%8A%9E%E5%85%AC%E6%96%87%E5%8D%B0/%E6%8A%BD%E7%BA%B8.jpeg",
          },
          {
            title: "定制笔记本",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E5%8A%9E%E5%85%AC%E6%96%87%E5%8D%B0/%E5%AE%9A%E5%88%B6%E7%AC%94%E8%AE%B0%E6%9C%AC.jpg",
          },
          {
            title: "定制工牌",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E5%8A%9E%E5%85%AC%E6%96%87%E5%8D%B0/%E5%AE%9A%E5%88%B6%E5%B7%A5%E7%89%8C.jpg",
          },
          {
            title: "定制U盘",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E5%8A%9E%E5%85%AC%E6%96%87%E5%8D%B0/%E5%AE%9A%E5%88%B6U%E7%9B%98-2.png",
          },
          {
            title: "发票信封",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E5%8A%9E%E5%85%AC%E6%96%87%E5%8D%B0/%E5%8F%91%E7%A5%A8%E4%BF%A1%E5%B0%81-2.png",
          },
          {
            title: "钢笔",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E5%8A%9E%E5%85%AC%E6%96%87%E5%8D%B0/%E9%92%A2%E7%AC%94.jpeg",
          },
          {
            title: "DIY名片",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/%E5%8A%9E%E5%85%AC%E6%96%87%E5%8D%B0/DIY%E5%90%8D%E7%89%87-2.png",
          },
        ],
        third: [
          {
            title: "帆布包",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/img_rzlb-fbb.png",
          },
          {
            title: "马克杯",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/img_rzlb-mkb.png",
          },
        ],
        fourth: [
          {
            title: "帆布包",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/img_rzlb-fbb.png",
          },
          {
            title: "马克杯",
            img_url:
              "https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/img_rzlb-mkb.png",
          },
        ],
      },
    };
  },
  components: {
    FooterBar,
  },
  methods: {
    changeItem(curItem) {
      this.curItem = curItem;
    },
  },
};
</script>

<style lang="scss">
@import "./style/index.scss";
</style>
