<template>
  <div class="container">
    <!-- banner -->
    <section class="banner">
      <img
        style="width: 100%"
        src="https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/img_banner-service.jpeg"
        alt="banner_服务支持"
      />
      <!-- <el-carousel :autoplay="false" height="380px">
        <el-carousel-item v-for="item in 1" :key="item">
          <img
            src="../assets/img_banner-about-us.jpeg"
            alt="banner"
          />
        </el-carousel-item>
      </el-carousel> -->
    </section>

    <!-- 服务与支持 -->
    <section class="service-support">
      <div>服务与支持</div>
      <div class="service-support-container display_flex justify-content_space-between">
        <div
          @click="changeItem('solution')"
          class="service-support-item display_flex flex-direction_column justify-content_flex-center align-items_center"
          :class="{ active: curItem == 'solution' }"
        >
          <template v-if="curItem == 'solution'">
            <img
              src="https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/icon_solution-active.png"
              alt="图片-多场景的解决方案"
            />
            <div class="line-arrow"></div>
          </template>
          <img
            v-else
            src="https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/icon_solution-default.png"
            alt="图片-多场景的解决方案"
          />

          <span>多场景的解决方案</span>
        </div>
        <div
          @click="changeItem('pay')"
          class="service-support-item display_flex flex-direction_column justify-content_flex-center align-items_center"
          :class="{ active: curItem == 'pay' }"
        >
          <template v-if="curItem == 'pay'">
            <img
              src="https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/icon_pay-active.png"
              alt="图片-快速支付"
            />
            <div class="line-arrow"></div>
          </template>

          <img
            v-else
            src="https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/icon_pay-default.png"
            alt="图片-快速支付"
          />
          <span>快速支付</span>
        </div>
        <div
          @click="changeItem('SCM')"
          class="service-support-item display_flex flex-direction_column justify-content_flex-center align-items_center"
          :class="{ active: curItem == 'SCM' }"
        >
          <template v-if="curItem == 'SCM'">
            <img
              src="https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/icon_SCM-active.png"
              alt="图片-柔性可定制供应链"
            />
            <div class="line-arrow"></div>
          </template>

          <img
            v-else
            src="https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/icon_SCM-default.png"
            alt="图片-柔性可定制供应链"
          />

          <span>柔性可定制供应链</span>
        </div>
        <div
          @click="changeItem('ai')"
          class="service-support-item display_flex flex-direction_column justify-content_flex-center align-items_center"
          :class="{ active: curItem == 'ai' }"
        >
          <template v-if="curItem == 'ai'">
            <img
              src="https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/icon_ai-active.png"
              alt="图片-AI赋能智能定制系统"
            />
            <div class="line-arrow"></div>
          </template>

          <img
            v-else
            src="https://fastmk-static.oss-cn-shanghai.aliyuncs.com/website/icon_ai-default.png"
            alt="图片-AI赋能智能定制系统"
          />
          <span>AI赋能智能定制系统</span>
        </div>
      </div>

      <div>
        <div class="line"></div>
      </div>

      <div class="service-support-content">
        {{ itemTextConfig[curItem] }}
      </div>
    </section>

    <!-- 底部栏 -->
    <footer-bar :border_hide="true" />
  </div>
</template>

<script>
import FooterBar from "../components/FooterBar";

export default {
  name: "ServiceSupport",
  data() {
    return {
      curItem: "pay",
      itemTextConfig: {
        solution: `闪定科技为客户提供便捷快速的支付，建立了面向客户的高效协同运作系统。
        交付团队全程参与解决方案的制定，保障快速交付。
        公司的信息和自动化管理，一体化解决方案、区域化网店运营、完备的物流配送等，为快速响应客户需求并最短时间交付产品提供了坚实的保障。`,
        pay: `闪定科技为客户提供便捷快速的支付，建立了面向客户的高效协同运作系统。
交付团队全程参与解决方案的制定，保障快速交付。
公司的信息和自动化管理，一体化解决方案、区域化网店运营、完备的物流配送等，为快速响应客户需求并最短时间交付产品提供了坚实的保障。`,
        SCM:
          "供应链--闪定科技为客户提供便捷快速的交付，建立了面向客户的高效协同运作体系。",
        ai: "智能-闪定科技为客户提供便捷快速的交付，建立了面向客户的高效协同运作体系。",
      },
    };
  },
  components: {
    FooterBar,
  },
  methods: {
    changeItem(curItem) {
      this.curItem = curItem;
    },
  },
};
</script>

<style scoped lang="scss">
.service-support {
  background-color: #fff;
  text-align: center;
  padding-bottom: 28px;
  padding: 0 100px;

  > div:nth-child(1) {
    font-size: 30px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #16181d;
    line-height: 45px;
    padding: 60px 0 54px 0;
  }
}

.service-support-container {
  margin: 0 120px 30px 120px;

  .service-support-item {
    width: 190px;
    height: 190px;
    border-radius: 50%;
    background: #f5f9fd;
    color: #1a8fff;
    cursor: pointer;
    position: relative;

    .line-arrow {
      left: 50%;
      margin-left: -10px;
    }

    img {
      width: 60px;
      height: 60px;
      margin-bottom: 12px;
    }

    span {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 22px;
    }
  }

  .service-support-item.active {
    background: #1a8fff;
    color: #fff;
    box-shadow: 0px 20px 40px 0px rgba(29, 51, 107, 0.1);
  }
}

.line {
  width: 100%;
  height: 1px;
  background-color: #b0b6c5;
}

.line-arrow {
  position: absolute;
  top: 200px;
}
.line-arrow:before,
.line-arrow:after {
  position: absolute;
  content: "";
  border-top: 10px transparent dashed;
  border-left: 10px transparent dashed;
  border-right: 10px transparent dashed;
  border-bottom: 10px #fff solid;
}
.line-arrow:before {
  border-bottom: 10px #b0b6c5 solid;
}
.line-arrow:after {
  top: 1px; /*覆盖并错开1px*/
  border-bottom: 10px #fff solid;
}

.service-support-content {
  padding: 40px 220px 50px 220px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #16181d;
  line-height: 24px;
}
</style>
